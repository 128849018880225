import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { BASE_BREADCRUMBS } from "../constants"

// Markup
const NotFoundPage = () => {
  return (
    <Layout
      pageTitle="Not Found"
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/404", name: "404" }]}>
      <div className="not-found-404 my-4 text-center">We're sorry. The page you were looking for does not exist. You can return to
        <Link to="/" className="ml-1">home</Link> or you can
        <Link to="/contact/" className="ml-1">contact us</Link> if you need additional help. Thanks!</div>
    </Layout>
  )
}

export default NotFoundPage
